import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Sagittarius.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Sagittarius Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/sagittarius"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Sagittarius</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Sagittarius Health</h4>
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Sagittarius Health</h1>
                <h2 className="text-sm md:text-base ml-4">Nov 22 - Dec 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Sagittarius Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Sagittarius Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Sagittarius Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-love");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-nature");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-man");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-traits");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-facts");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Sagittarius Health</p><br/>
            The Sagittarius-born natives are most potent, particularly in the early part of their lives; typically, they don’t have to face any fitness problems. But they are inclined to over-indulgence, which can guide problems related to the liver. Their thighs, stomach, feet, and hips are also susceptible. Primarily, they need to change their habits of not drinking too much; they should avoid smoking too much and follow a nutritious diet for a healthy life. If they can maintain the best lifestyle, they can prevent blood pressure or liver disorder-related problems. They may also share some issues of sciatica or torn thigh ligaments. They should move carefully to avoid mishaps. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Possible health concerns</p><br/>
            Jupiter, which is the marvelous planet in the Solar system, rules your Zodiac Sign. The Sagittarius is thus associated with the main organ liver, the biggest organ in the human body. Since this is a weak spot, they should be careful about liver-related (hepatic) ailments. Drinking too much alcohol or any other toxic imports should be avoided. Also, you can maintain your energy and control to waste your excessive energy on unwanted things. Yoga and meditation help you a lot; you can do deep breathing daily, which may help you and cure many problems. After that, most importantly, you also need to care about your immune system. Whatever you eat directly affects your health. It would be best if you were careful about that.  <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Food for Sagittarius</p><br/>
            When taking care of your food and nutrition, you can only choose healthy items rich in vitamins and minerals. Your diet should include chicken, fish, beetroot, oats, eggs, raw eggs, strawberries, and apples; for skin; and you need to intake fruits and vegetables, dates, cherries, green beans, corn, and many more. They have to avoid alcohol and smoking for a healthy life altogether. Sometimes, they are very lazy in skipping meals at times, so they should have some refreshed fruits and healthy snacks handy and drink plenty of water. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Sagittarius Physical Structure</p><br/>
            If we talk about Sagittarius, people under this zodiac sign are generally tall in height and have thick and shiny hair. You will especially find them in a joyful mood, and their bright eyes make them very appealing. Their voice is also very tempting. Their hands tend to be lengthier than their trunks. Their nose is generally big, as are their top-row teeth. In middle age, they tend to gain weight because of avoidable addictions and become restless. Their impression is gay and cheerful, and their sense of attire is informal, but they dress as per the event. They are adjustable and can vary as the occasions change. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Sagittarius Beauty</p><br/>
            These people choose a relaxed dressing style as they can maintain it well. They look fantastic in T-shirts, chopped-off shorts, with some quirky sports cap. Because they have attractive long legs, leggings and tight jeans enrich their beauty. Light purple is their favorite color, and it improves their friendly, optimistic nature. These people are usually very busy with other things to be concerned about clothes. They select to wear clothes that will serve them well in any temperature. You know more about your sign in detail as an astrologer. You might also be curious to read about Sagittarius Decans. <br></br>
            <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
